import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Head from 'next/head';
import React, { useState } from 'react';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import HomePageCard from '../components/HomePageCard';
import Landing from '../components/Landing';
import { msg } from '../messages';
import { api_endpoint } from '../settings';
import { sanitizeTitle } from '../util';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "1rem",
    position: 'relative',
    paddingTop: 30,
  },
}));

const chunkSize = 18
export default function Home(initial_data) {

  const classes = useStyles();
  const [data, setData] = useState(initial_data.data)
  const [dataCounter, setDataCounter] = useState(chunkSize)
  const [hasMore, setHasMore] = useState(true)
  const [fetching, setFetching] = useState(false)

  const fetchData = () => {
    setFetching(true)
    axios.get('/api/get_reviews/' + dataCounter + '/' + chunkSize)
      .then(function (response) {
        if (response.data === null || response.data.length === 0) {
          setHasMore(false)
        }
        else {
          setData(data.concat(response.data))
          setDataCounter(dataCounter + chunkSize)
        }
      }).catch(function (error) {
        // handle error
        console.log(error);
      }).then(function () {
        // always executed
        setFetching(false)
      })
  };

  return (
    <React.Fragment>
      <Head>
        <title>{msg.home_page_title}</title>
        <meta name="description" content={msg.home_page_desc} />
        <link rel="canonical" href={msg.home_page_canonical} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      </Head>

      <AppBar cats={initial_data.cats} />
      <Landing />

      <Container className={classes.container}>


        <Grid container spacing={3}>
          {
            data.map(item => (
              <HomePageCard
                img={item.img}
                title={sanitizeTitle(item.title)}
                r_id={item.id}
                count={item.review_count}
                avg_star={item.avg_star}
                price={item.price}
                key={item.id} />
            ))
          }
        </Grid>

        {hasMore &&
          <div style={{ marginTop: "5rem", marginBottom: "5rem" }} >
            {fetching ? msg.infinite_loop_loading : <Button variant="outlined" color="primary" onClick={fetchData}>{msg.more}</Button>}
          </div>
        }

      </Container>
      <Footer></Footer>
    </React.Fragment>
  )
}

export async function getServerSideProps() {

  let data = null

  await axios.get(api_endpoint + '/get_reviews/0/' + chunkSize)
    .then(function (response) {
      if (response.data === null || response.data.length === 0) {
        console.log("ss no data")
      }
      else {
        data = response.data
      }
    }).catch(function (error) {
      console.log(error);
    }).then(function () {
    })

  let cats = null
  await axios.get(api_endpoint + '/get_categories')
    .then(function (response) {
      if (response.data === null || response.data.length === 0) {
        console.log("ss no cats")
      }
      else {
        cats = response.data
      }
    }).catch(function (error) {
      console.log(error);
    }).then(function () {
    })

  return { props: { data: data, cats: cats } }
}