import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { msg } from '../messages';
import { getSiteImg } from '../util';

const useStyles = makeStyles((theme) => ({
    wrapperBg: {
        overflow: "hidden",
        height: "75vh",
        marginTop: 55,
        background: "#000d46",
        position: "relative",
        [theme.breakpoints.down('xs')]: {
            marginTop: 75,
        },
    },
    headText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        },
    },
    bodyText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem'
        },
    },
    divider: {
        width: 73,
        height: 4,
        marginTop: 8,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        display: "block",
        backgroundColor: "#ff3366"
    },
    arrowDown: {
        position: "absolute",
        bottom: 16
    },
    '@keyframes slide': {
        "0%": {
            transform: "translate3d(0, 0, 0)"
        },
        "100%": {
            transform: "translate3d(0, -600px, 0)"
        }
    },
    slidingBackground: {
        background: "url(" + getSiteImg("landing_img.jpg") + ") repeat-y",
        height: 1600,
        width: 5076,
        animation: "$slide 45s linear infinite",
        opacity: 0.15,
        position: "absolute"
    },
    '@media (max-width: 420px)': {
        slidingBackground: {
            background: "url(" + getSiteImg("landing_img_sm.jpg") + ") repeat-y",
        }
    },
    landingBox: {
        color: "white",
        margin: 0,
        position: "absolute",
        top: "50%",
        "-ms-transform": "translateY(-50%)",
        transform: "translateY(-50%)",
        width: "100%"
    }
}));

export default function Landing() {

    const classes = useStyles();

    return (
        <Grid className={classes.wrapperBg}>
            <div className={classes.slidingBackground}></div>
            <Grid className={classes.landingBox}>
                <Typography variant="h3" component="h1" className={classes.headText}>
                    {msg.home_page_landing_title}
                </Typography>
                <Divider className={classes.divider}></Divider>
                <br />
                <Typography variant="h4" component="h2" className={classes.bodyText} dangerouslySetInnerHTML={{ __html: msg.home_page_landing_desc }}>
                </Typography>
            </Grid>
            <img
                className={classes.arrowDown}
                src={getSiteImg("arrowDown.png")}
                alt="down arrow"
                loading="lazy"
                decoding="async">
            </img>
        </Grid>
    )
}
